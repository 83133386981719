import { BackButton, MainButton, SecondaryButton} from "@twa-dev/sdk/react";
import React from "react";
import browserHistory from "../../browser-history.ts";
import { Container } from "react-bootstrap";
import SpotyPlus from '../../assets/spoty-plus.svg';
import TgStar from '../../assets/tg-star.svg';
import './style.css';
import TickIcon, { TickType } from "../../components/images/tick/index.tsx";
import { CreateInvoiceLinkResponse, PaymentType, Theme } from "../../types/data.ts";
import { api } from "../../services/api.ts";
import { APIRoute, AppRoute } from "../../const.ts";
import WebApp from "@twa-dev/sdk";
import SpotyPlusBG from '../../assets/spoty-plus-bg.png';
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/index.ts";
import { useTranslation } from "react-i18next";


type AdvantagesListProps = {
    advantages: string[];
};

const AdvantagesList = ({advantages}: AdvantagesListProps) => {
    return (
        <ul className="advantages-list">
            {advantages.map((adv) => <li><i>{<TickIcon type={TickType.Icon} />}</i>{adv}</li>)}
        </ul>
    )
};

type ContentProps = {
    theme: Theme;
}

type SubscriptionInfoContentProps = ContentProps & {
    subscriptionPrice: number;
}

const SubscriptionInfoContent = ({theme, subscriptionPrice}: SubscriptionInfoContentProps) => {
    const { t, i18n } = useTranslation();
    const advantages = [
        t('SubscribeAdvantageOne'),
        t('SubscribeAdvantageTwo'),
        t('SubscribeAdvantageThree')
    ];

    const handleSubscriptionPaymentClick = () => {
        const createInvoiceLink = async () => {
            const paymentData = {
                amount: subscriptionPrice,
                type: PaymentType.Subscription,
                monthsCount: 1
            };
            const { data: invoiceLinkData } = await api.post<CreateInvoiceLinkResponse>(APIRoute.Payment, paymentData);

            return invoiceLinkData;
        };

        createInvoiceLink().then((result) => {
            WebApp.openInvoice(result.link);
        });
    };

    const mainBtnTextColor = theme === Theme.Dark ? '#000' : '#fff';
    const mainBtnBgColor = theme === Theme.Dark ? '#fff': '#000';

    return (
        <div className="subscription-content">
            <header className="subscription-header">
                <img src={SpotyPlus} />
                <h1>{t('SubscribeTitle')}</h1>
                <AdvantagesList advantages={advantages} />
            </header>
            <footer className="subscription-footer">
                <MainButton 
                    text={t('SubscribeAction')}
                    hasShineEffect={true} 
                    color={mainBtnBgColor} 
                    textColor={mainBtnTextColor} 
                    onClick={handleSubscriptionPaymentClick}
                />
                <p>{t('SubscribeDescriptionFirstPart')} <img src={TgStar} />{subscriptionPrice} {t('SubscribeDescriptionSecondPart')}</p>
            </footer>
        </div>
    );
};

const SubscribedContent = ({theme}: ContentProps) => {
    const mainBtnTextColor = theme === Theme.Dark ? '#000' : '#fff';
    const mainBtnBgColor = theme === Theme.Dark ? '#fff': '#000';
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    
    const handleToExercisesBtnClick = () => {
        navigate(AppRoute.Main);
    };

    return (
        <div className="subscribed-content">
            <div className="subscribed-image">
                <img src={SpotyPlusBG} alt="Spoty Plus" />
            </div>
            <main>
                <img src={SpotyPlus} alt="Spoty Plus" />
                <h1>{t("SubscribedTitle")}</h1>
                <p>{t("SubscribedText")}е</p>
            </main>
            <footer>
                <MainButton text={t("ToExercises")} onClick={handleToExercisesBtnClick} color={mainBtnBgColor} hasShineEffect={true} textColor={mainBtnTextColor} />
            </footer>
        </div>
    );
};

const SubscriptionPage = () => {
    const { user, theme, subscriptionInfo } = useAppSelector((state) => state);
    const hasSubscription = !!user?.subscription;
    
    return (
        <div className="subscription-page">
            <BackButton onClick={() => browserHistory.back()}/>
            <Container>
                {
                    hasSubscription 
                    ? <SubscribedContent theme={theme} /> 
                    : <SubscriptionInfoContent subscriptionPrice={subscriptionInfo ? subscriptionInfo.price : 1}  theme={theme} />
                }
            </Container>
        </div>
    )
};


export default SubscriptionPage;