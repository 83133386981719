import axios, { AxiosInstance, AxiosRequestConfig, AxiosError, AxiosResponse } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { toast } from 'react-toastify';
import WebApp from '@twa-dev/sdk';

const BASE_URL = process.env.REACT_APP_API_URL;
const BASE_API_URL = `${BASE_URL}/api`
export const BASE_MEDIA_URL = `${BASE_URL}/media/`;
const TIMEOUT = 5000;

const StatusCodeMapping: Record<number, boolean> = {
  [StatusCodes.BAD_REQUEST]: true,
  [StatusCodes.UNAUTHORIZED]: true
};

const shouldDisplayError = (response: AxiosResponse) => !!StatusCodeMapping[response.status];


export const getImageURL = (imageName: string) => {
  return BASE_MEDIA_URL + imageName;
}

const createAPI = (): AxiosInstance => {
  const api = axios.create({
    baseURL: BASE_API_URL,
    timeout: TIMEOUT,
    validateStatus: (status: number) => {
      return (status >= 200 && status < 300) || status == 404;
    }
  });

  api.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      if (!config.headers){
        return config;
      }

      const languageCode = WebApp.initDataUnsafe.user?.language_code;

      if (!languageCode || (languageCode !== "en" && languageCode !== "ru")){
        config.headers['App-Language'] = 'en';
      } else {
        config.headers['App-Language'] = languageCode;
      };

      config.headers["User-Data"] = WebApp.initData;

      return config;
    }
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError) => {
      if (error.response && shouldDisplayError(error.response)) {
        toast.warn(error.message);
      }
      
      throw error;
    }
  );

  return api;
};

export const api = createAPI();