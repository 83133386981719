import { createAction } from '@reduxjs/toolkit';
import { Category, ExercisePreview, Theme } from '../types/data';
import { User } from '../types/user';
import { SubscriptionInfo } from '../types/subscription';


export const setCategories = createAction<Category[]>('setCategories');
export const setSavedExercises = createAction<ExercisePreview[]>('setSavedExercises');
export const setDataLoadedStatus = createAction<boolean>('setDataLoadedStatus');
export const setTheme = createAction<Theme>('setTheme');
export const setError = createAction<boolean>('setError');
export const setUserInfo = createAction<User>('setUserInfo');
export const setIsFullscreen = createAction<boolean>('setIsFullScreen');
export const setSubscriptionInfo = createAction<SubscriptionInfo>('setSubscriptionInfo');