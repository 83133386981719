import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthData, PaymentData } from '../types/request-data.ts';
import { AppDispatch, State } from '../types/store.ts';
import { AxiosInstance } from 'axios';
import { APIRoute } from '../const.ts';
import { Category, ExercisePreview } from '../types/data.ts';
import { setCategories, setDataLoadedStatus, setSavedExercises, setUserInfo, setSubscriptionInfo } from './action.ts';
import { User } from '../types/user.ts';
import { SubscriptionInfo } from '../types/subscription.ts';


export const getCategoriesAction = createAsyncThunk<void, undefined, {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
}>(
  'data/Categories',
  async (_arg, {dispatch, extra: api}) => {
    const { data: categories } = await api.get<Category[]>(APIRoute.Categories);
    dispatch(setCategories(categories));
  }
);


export const getSavedExercises = createAsyncThunk<void, undefined, {
    dispatch: AppDispatch;
    state: State;
    extra: AxiosInstance;
}>(
    'data/SavedExercises',
    async (_arg, {dispatch, extra: api}) => {
        const { data: exercisesPreviews } = await api.get<ExercisePreview[]>(APIRoute.SavedExercises);
        dispatch(setSavedExercises(exercisesPreviews));
        dispatch(setDataLoadedStatus(true));
    }
);

export const getUserInfo = createAsyncThunk<void, undefined, {
  dispatch: AppDispatch;
  state: State;
  extra: AxiosInstance;
}>(
  'user/Me',
  async (_arg, {dispatch, extra: api}) => {
      const { data: userInfo } = await api.get<User>(APIRoute.UserInfo);
      dispatch(setUserInfo(userInfo));
  }
);

export const getSubscriptionInfo = createAsyncThunk<void, undefined, {
  dispatch: AppDispatch;
  state: State;
  extra: AxiosInstance;
}>(
  'subscription/Info',
  async (_arg, {dispatch, extra: api}) => {
    const { data: subscriptionInfo } = await api.get<SubscriptionInfo>(APIRoute.SubscriptionInfo);
    dispatch(setSubscriptionInfo(subscriptionInfo));
  }
)

