import React from "react";
import browserHistory from "../../browser-history.ts";
import { BackButton } from "@twa-dev/sdk/react";
import './style.css';
import { Col, Container, Row } from "react-bootstrap";
import Logo from '../../assets/logo.png';
import { useTranslation } from "react-i18next";

const AboutPage = () => {
    const { t, i18n } = useTranslation();

    return (
        <div className="about-page">
            <BackButton onClick={() => browserHistory.back()}/>
            <Container>
                <Row>
                    <Col>
                        <div className="about-content">
                            <header className="about-header">
                                <img src={Logo} alt="Spoty logo" />
                                <h1>Spoty</h1>
                                <p>{t("Version")}</p>
                                <p>{t("Version Date")}</p>
                            </header>
                            <div className="about-contacts">
                                <div className="about-contact-item">
                                    <p>{t("Development")}</p>
                                    <a className="about-contact-link" href="https://t.me/fregire">@fregire</a>
                                </div>
                                <div className="about-contact-item">
                                    <p>{t("Design")}</p>
                                    <a className="about-contact-link" href="https://t.me/starodumov">@starodumov</a>
                                </div>
                                <div className="about-contact-item">
                                    <p>{t("Texts")}</p>
                                    <a className="about-contact-link" href="https://t.me/fucksupercool">@fucksupercool</a>
                                    <a className="about-contact-link" href="https://t.me/artik611">@artik611</a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default AboutPage;