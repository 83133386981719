export enum AppRoute {
    Main = '/',
    Category = '/category/:slug',
    Exercise = '/exercises/:id',
    Saved = "/saved",
    About = '/about',
    Feedback = '/feedback',
    Subscription = '/subscription'
}


export enum APIRoute {
    Payment = '/bot/invoice',
    Categories = '/categories',
    SavedExercises = '/exercises/saved',
    Feedback = '/feedback',
    UserInfo = '/users/me',
    SubscriptionInfo = "/subscription/info"
}


export const BOT_USERNAME = 'spoty_app_bot';