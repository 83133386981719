import { createReducer } from "@reduxjs/toolkit";
import { User } from "../types/user.ts";
import { Category, ExercisePreview, Theme } from "../types/data.ts";
import { setCategories, setDataLoadedStatus, setError, setSavedExercises, setTheme, setUserInfo, setIsFullscreen, setSubscriptionInfo } from "./action.ts";
import { SubscriptionInfo } from "../types/subscription.ts";

type AppState = {
    name: string;
    user?: User;
    categories?: Category[];
    savedExercises: ExercisePreview[];
    isDataLoaded: boolean;
    theme: Theme;
    hasError: boolean;
    isFullScreen: boolean;
    subscriptionInfo?: SubscriptionInfo;
};

const initialState: AppState = {
    name: "TestName",
    categories: [],
    savedExercises: [],
    isDataLoaded: false,
    theme: Theme.Dark,
    hasError: false,
    isFullScreen: true,
    subscriptionInfo: {
        price: 1
    }
};


export const reducer = createReducer(initialState, (builder) => {
    builder
    .addCase(setCategories, (state, action) => {
        state.categories = action.payload;
    })
    .addCase(setSavedExercises, (state, action) => {
        state.savedExercises = action.payload || [];
    })
    .addCase(setDataLoadedStatus, (state, action) => {
        state.isDataLoaded = action.payload;
    })
    .addCase(setTheme, (state, action) => {
        state.theme = action.payload;
    })
    .addCase(setError, (state, action) => {
        state.hasError = action.payload;
    })
    .addCase(setUserInfo, (state, action) => {
        state.user = action.payload;
    })
    .addCase(setIsFullscreen, (state, action) => {
        state.isFullScreen = action.payload;
    })
    .addCase(setSubscriptionInfo, (state, action) => {
        state.subscriptionInfo = action.payload;
    });
});

