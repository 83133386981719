import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.tsx';
import reportWebVitals from './reportWebVitals.js';
import WebApp from '@twa-dev/sdk';
import './assets/fonts/SFProText-Regular.ttf';
import './assets/fonts/SFProText-Semibold.ttf';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { store } from './store/index.ts';
import { getCategoriesAction, getSavedExercises, getUserInfo, getSubscriptionInfo } from './store/api-actions.ts';
import i18n from './i18n.ts';
import { setTheme, setIsFullscreen } from './store/action.ts';
import BrowserLaunch from './pages/browser-launch/index.tsx';
import { BASE_MEDIA_URL } from './services/api.ts';


if (WebApp.initData){
  WebApp.requestFullscreen();
  WebApp.ready();
  WebApp.expand();

  const isDesktop = !["android", "android_x", "ios"].includes(WebApp.platform);

  if (isDesktop){
    window.onresize = (e) => {
      if (window.innerWidth > 640) {
        store.dispatch(setIsFullscreen(true));
      } else {
        store.dispatch(setIsFullscreen(false));
      }
    };
  };
  
  store.dispatch(setTheme(WebApp.colorScheme));
  WebApp.onEvent("themeChanged", () => {
    store.dispatch(setTheme(WebApp.colorScheme));
  });
  
  WebApp.onEvent('invoiceClosed', ({url, status}) => {
    if (status === 'paid'){
      store.dispatch(getUserInfo());
    };
  });
  const languageCode = WebApp.initDataUnsafe.user.language_code;
  if (!languageCode || (languageCode !== "en" && languageCode !== "ru")){
    i18n.changeLanguage("en");
    console.log("not found required language " + languageCode);
  } else {
    i18n.changeLanguage(languageCode);
    console.log("language code = " + languageCode);
  }
  
  store.dispatch(getSubscriptionInfo());
  store.dispatch(getUserInfo());
  // TODO: do smth with parallel requests
  store.dispatch(getCategoriesAction()).then(_ => store.dispatch(getSavedExercises()));
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ToastContainer />
    {WebApp.initData ? <App /> : <BrowserLaunch />}
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
